import React, { useEffect, useState } from 'react'

function Confide() {
    const [mobile, SetImobile] = useState(window.innerWidth < 507)
    useEffect(() => {
        const hundleSize = () => {
            SetImobile(window.innerWidth < 507)
        }
        window.addEventListener('resize', hundleSize)
        return () => window.removeEventListener('resize', hundleSize)
    }, [])
    return (
        <div class="p-4">
            <div class="bg-white rounded-lg border p-4">
                <h1 class={` font-bold ${mobile ? 'text-[20px]' : 'text-[25px]'} text-blue-800 font-serif text-center w-full`}>
                    Politique de confidentialité de SchoolManage

                </h1>
                <div class="mt-4">
                    <div class="mt-10">
                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>
                                Utilisation des données
                            </p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600 list-disc pl-7'>
                                <li className='mb-2'>
                                    <span className='font-bold text-black'>
                                        Objectifs de l'utilisation des données collectées
                                    </span>
                                    <span className='block mt-2 text-gray-600'>
                                        Les données collectées sont utilisées dans le cadre des services de gestion du secrétariat scolaire proposés par notre application. Nous collectons des informations personnelles telles que le Nom, Prénom, Adresses e-mail, Numéro de Téléphone, et les informations de paiement nécessaires pour faciliter les opérations administratives.
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='font-bold text-black'>
                                        Les données collectées sont utilisées pour :
                                    </span>
                                    <span className='block mt-2 text-gray-600'>
                                        Gérer les dossiers des étudiants et du personnel éducatif.
                                        Faciliter la communication entre le secrétariat, les enseignants, les élèves et les parents.
                                        Vérifier l'identité des utilisateurs et assurer la sécurité des données académiques.
                                        Optimiser l'expérience utilisateur et garantir la compatibilité avec les systèmes d'exploitation et les appareils pris en charge.
                                        Effectuer des analyses pour améliorer nos services et répondre aux besoins des utilisateurs.
                                    </span>
                                </li>
                            </ul>

                        </p>

                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>
                                Partage de données
                            </p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600 list-disc pl-7'>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        Nous tenons à souligner que nous ne partageons pas les données personnelles collectées avec des tiers, sauf dans les cas expressément autorisés par les utilisateurs ou exigés par la loi.

                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        Nous ne vendons, ne louons et ne divulguons pas les informations personnelles des utilisateurs à des tiers à des fins commerciales ou publicitaires.
                                        Les données collectées sont strictement utilisées à des fins internes, et nous ne les partageons pas en dehors de notre organisation ou de notre application.
                                    </span>
                                </li>
                            </ul>

                        </p>



                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>
                                Mesures de sécurité

                            </p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600 list-disc pl-7'>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        Nous accordons une grande importance à la sécurité des données de nos utilisateurs. Nous avons mis en place des mesures de sécurité appropriées pour protéger vos informations personnelles contre tout accès non autorisé, toute divulgation ou toute utilisation abusive.

                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        Vos données personnelles sont stockées dans notre système sécurisé, où elles sont protégées par des mesures techniques, organisationnelles et administratives appropriées.
                                        Nous nous engageons à ne pas partager vos données personnelles avec des tiers, sauf si cela est expressément autorisé par vous ou si cela est requis par la loi.
                                        Cependant, veuillez noter que malgré nos efforts pour assurer la sécurité des données, aucune méthode de transmission ou de stockage électronique n'est totalement sécurisée. Par conséquent, nous ne pouvons garantir une sécurité absolue des données.
                                    </span>
                                </li>
                            </ul>

                        </p>

                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>
                                Services tiers
                            </p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600 list-disc pl-7'>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        À l'heure actuelle, notre application n'intègre aucun service tiers. Toutes les données collectées sont utilisées exclusivement à des fins internes, conformément à notre politique de confidentialité.

                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        Cependant, nous prévoyons d'intégrer des services tiers dans le futur pour améliorer certaines fonctionnalités de l'application. Lorsque cela se produira, nous mettrons à jour notre politique de confidentialité pour inclure des informations détaillées sur ces services tiers, y compris la manière dont ils traitent les données des utilisateurs.

                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        Nous vous encourageons à consulter régulièrement notre politique de confidentialité pour être informé des mises à jour et des changements concernant les services tiers intégrés à notre application.


                                    </span>
                                </li>
                            </ul>

                        </p>

                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>
                                Mises à jour de la politique de confidentialité

                            </p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2 text-gray-600 list-disc pl-7'>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        Nous nous engageons à maintenir notre politique de confidentialité à jour pour refléter nos pratiques actuelles en matière de collecte, d'utilisation et de protection des données.

                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        En cas de changements importants dans notre politique de confidentialité, nous vous notifierons en temps réel, directement via l'application ou par un autre moyen de communication approprié.

                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        Nous utiliserons l'adresse e-mail que vous avez fournie lors de votre inscription pour vous informer des mises à jour de la politique de confidentialité. Il est de votre responsabilité de vous assurer que votre adresse e-mail est à jour et que vous consultez régulièrement vos e-mails.


                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='block mt-2 text-gray-600'>
                                        Nous pourrons également afficher un avis clair et visible dans l'application pour vous informer des modifications de la politique de confidentialité et vous permettre d'en prendre connaissance avant de continuer à utiliser nos services.

                                    </span>
                                </li>
                            </ul>

                        </p>

                        <p className='mt-2 bg-slate-100 rounded-md '>
                            <p class={`font-semibold text-gray-500   ${mobile ? 'text-[16px] text-center w-full ' : 'text-[20px] pl-5 '}`}>
                                Conclusion


                            </p>
                            <div className='h-[1px] bg-slate-400 w-full mt-2'></div>
                            <ul className='py-2  list-disc pl-7 block mt-2 text-gray-600'>
                                En conclusion, nos politiques de confidentialité visent à garantir la protection et la sécurité des données personnelles de nos utilisateurs. Nous collectons et utilisons les données de manière responsable, en respectant les lois et réglementations applicables. Les données collectées sont utilisées uniquement dans le cadre des services de gestion du secrétariat scolaire que nous proposons, et ne sont pas partagées avec des tiers, sauf dans les cas autorisés par les utilisateurs ou exigés par la loi. Nous prenons des mesures de sécurité appropriées pour protéger les données, bien que nous ne puissions garantir une sécurité absolue. En cas de modifications importantes de notre politique de confidentialité, nous informerons les utilisateurs en temps réel, notamment par e-mail et via des notifications dans l'application. Nous nous engageons à maintenir notre politique de confidentialité à jour et à respecter la confidentialité des données de nos utilisateurs.

                            </ul>

                        </p>
                    </div>
                    <p className='text-gray-500 mt-10 text-center'>
                        En utilisant notre application SchoolManage, vous acceptez notre
                        politique de confidentialité. Si vous avez des questions ou des
                        préoccupations concernant la confidentialité de vos informations, veuillez
                        nous contacter à l'adresse fournie dans l'application ou sur cet adresse e-mail :{" "}
                        <a href="mailto:btr.dev@burundientempsreel.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
                            btr.dev@burundientempsreel.com
                        </a>

                    </p>
                </div>
            </div>
        </div >


    )
}

export default Confide
